
import { Component, Vue } from 'vue-property-decorator'
import { OrdinarySeedling } from '@/types/ordinarySeedling.d'
import ImportData from '@/components/importTemplateData/Index.vue'

@Component({
  name: 'OrdinarySeedling',
  components: { ImportData }
})
export default class extends Vue {
  private info = {
    projectId: '',
    plantName: '',
    seedlingType: '',
    treeAge: ''
  }

  private loading = false
  private tableData: OrdinarySeedling[] = []
  private typeList = []
  private isShowImport = false
  page = 1
  size = 10
  total = 0
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getTypeList()
    this.getData()
  }

  // 查询苗木类别列表
  getTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'seedlingType' }).then(res => {
      this.typeList = res.seedlingType || []
    })
  }

  // 导出
  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.seedling.exportSeedlingData, { isFamous: '0', ...this.info }).finally(() => {
      loading.close()
    })
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<OrdinarySeedling> }>(this.$apis.seedling.selectYhSeedlingByPage, {
      ...this.info,
      isFamous: '0',
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    this.$router.push({
      name: 'ordinarySeedlingAdd'
    })
  }

  onUpdate (seedlingId: string) {
    this.$router.push({
      name: 'ordinarySeedlingUpdate',
      params: { seedlingId }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.seedling.deleteYhSeedling, {
        seedlingId: id
      }).then(() => {
        this.$message.success('删除成功')
        this.page = 1
        this.getData()
      })
    })
  }

  onDetail (seedlingId: string) {
    this.$router.push({
      path: `/resource/ordinarySeedlingList/detail${seedlingId}`
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }
}
